.container {
    padding-right: 0;
    padding-left: 0;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .container {
        width: 738px;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
}

@media (min-width: 992px) {
    .container {
        width: 962px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

.main-container {
    color: #222;
    text-align: left !important;
    padding: 2em;
    background: #fff;
    min-height: 400px;
    -webkit-box-shadow: 0 0 3px 0 rgba(0, 0, 0, .25);
    -moz-box-shadow: 0 0 3px 0 rgba(0, 0, 0, .25);
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, .25)
}

.main-container * {
    text-align: left !important
}

.main-container :not(a) {
    color: #222
}

.main-container ins.adsbygoogle {
    background: #ccc
}

.main-container #content p {
    margin-top: 1rem;
    margin-bottom: 1rem
}

a {
    color: #009c9c
}

a:hover {
    color: #007bff
}

h2 {
    font-size: 1.5em
}

@media only screen and (max-width: 600px) {
    h2 {
        font-size: 1.2em
    }
}

a.nohover {
    color: #fff;
    text-decoration: none
}

footer {
    background: #222;
    padding: 20px 15px;
    font-size: 0.9em;
}

footer p {
    color: #666 !important;
}

footer a {
    color: #888;
    text-decoration: none;
}