.createProductContainer{
    /* background-color: rgb(0, 0, 22) !important; */
    padding: 0rem 2rem;
    
}
.upload-button{
    --tw-bg-opacity: 1;
    background-color: rgb(148 163 184 / var(--tw-bg-opacity));
}
.upload-button:hover{
    cursor: pointer;
    --tw-bg-opacity: 1;
    background-color: rgb(203 213 225 / var(--tw-bg-opacity));
}
.imgBox{
    place-content: center;
    place-items: center;
    gap: 2rem;
}